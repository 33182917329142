.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarStyle {
  display: inline-block;
  position: relative;
  top: 0;
  left: 35px;
  font-size: 12px;
  margin: 10px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.popup-content {
  padding: 8px;
}

.clickable-marker {
  filter: drop-shadow(0 0 3px white);
}

.mapboxgl-popup-content {
  background: #000;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #000;
}

.mapboxgl-popup-tip {
  border-top-color: #000 !important;
  border-bottom-color: #000 !important;
}

.popup-container {
  /* display: flex; */
  min-width: 300px;
}

.popup-listingName {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.mapboxgl-popup-content>div {
  width: 300px;
}

.mapboxgl-popup {
  max-width: initial !important;
}

.flex {
  flex: 1;
}