#root {
  background-color: black;
  height: 100%;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
}

.input-mfa {
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 24px;
  text-align: center;
  margin-right: 12px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.85);
  border: 1px solid #424242;
  border-radius: 4px;
  background: #141414;
  background-clip: padding-box;
}

.input-mfa:focus {
  border-color: #1fb572;
  box-shadow: 0 0 0 2px rgb(0 113 73 / 15%);
  border-inline-end-width: 1px;
  outline: 0;
}

.table-properties td{
  padding: 4px 4px !important;
}

#table-property-resizable-container .react-resizable {
  position: relative;
  background-clip: padding-box;
}

#table-property-resizable-container .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}


